<template>
    <div>
        <h1 class="page__title">Отзывы</h1>
        <div class="actions__container">
            <router-link class="btn btn-primary w-40 inline-block mr-1 mb-2" to="/reviews/create">
                Создать
            </router-link>
        </div>

        <div class="grid grid-cols-12 gap-6">
            <div :class="{ 'col-span-8': isSuperAdmin, 'col-span-12': !isSuperAdmin }">
                <ContentSearch />
            </div>

            <div v-if="isSuperAdmin" class="col-span-4">
                <Multiselect
                    name="type"
                    :value="null"
                    :searchable="false"
                    track-by="name"
                    label="name"
                    valueProp="id"
                    :options="affiliates"
                    :can-clear="true"
                    placeholder="Выберите филиал"
                    noResultsText="Не найдено"
                    noOptionsText="Не найдено"
                    v-model="affiliate"
                    @clear="handleAffiliateClear"
                    @select="handleAffiliateSelect"
                />
            </div>
        </div>

        <ContentTable
            :inverse-toggle-icons="true"
            :table-actions="tableActions"
            :headers="tableHeaders"
            :table-rows="tableData"
            :meta="pagination"
            :loading="loading"
            @edit="editAction"
            @published="handlePublishToggle"
            @delete="deleteAction"
        />

        <CrudModal
            :isOpen="modalOpen"
            :title="modalTitle"
            :type="modalType"
            :accept-callback="modalCallback"
            :modal-data="modalData"
            @update:isOpen="modalOpen = $event"
            @deleteModalAccepted="onDeleteModalAccepted"
        />
    </div>
</template>

<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import ContentTable from '@/components/tables/ContentTable';
import CrudModal from '@/components/modals/CrudModal';
import ContentSearch from '@/components/base/ContentSearch';
import { mapGetters } from 'vuex';

export default {
    components: { ContentSearch, CrudModal, ContentTable },
    mixins: [errorResponse],
    data() {
        return {
            loading: false,
            affiliate: false,
            modalComponent: () => import('@/components/modals/ModalDelete'),
            modalOpen: false,
            modalType: null,
            modalTitle: null,
            modalCallback: null,
            modalData: null,
            tableActions: [
                { name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                {
                    name: 'is_published',
                    title: 'опубликовано',
                    title2: 'не опубликовано',
                    class: 'bg-primary-10',
                    icon: 'toggle',
                    cbName: 'published',
                },
                { name: 'delete', title: 'удалить', class: 'bg-red-400', icon: 'TrashIcon', cbName: 'delete' },
            ],
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'name', label: 'Название' },
                { field: 'publication_date_formatted', label: 'Дата публикации' },
            ],
            tableData: [],
            pagination: null,
            affiliates: [],
        };
    },
    async created() {
        if (this.isSuperAdmin) {
            await this.fetchAffiliates();

            if (this.$route && this.$route.query && this.$route.query.affiliate_id) {
                this.affiliate = this.$route.query.affiliate_id;
            }
        }

        await this.fetchData(this.getQuery());
    },
    computed: {
        ...mapGetters({
            isSuperAdmin: 'user/isSuperAdmin',
            getUser: 'user/getUser',
        }),
    },
    methods: {
        getQuery(query = this.$route.query) {
            const affiliateIds =
                this.getUser && this.getUser.assigned_affiliates && this.getUser.assigned_affiliates.length > 0
                    ? this.getUser.assigned_affiliates.join(',')
                    : '';
            return this.isSuperAdmin ? query : Object.assign({}, query, { affiliate_id: affiliateIds });
        },
        async fetchData(params) {
            try {
                this.loading = true;
                const { data } = await this.axios.get('/reviews', { params });
                this.tableData = data.data;
                this.pagination = data.meta;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch data: ' + ex);
            }
        },
        async fetchAffiliates() {
            try {
                this.loading = true;
                const { data } = await this.axios.get('/affiliates', { params: { paginate: false } });
                this.affiliates = [{ id: null, name: 'Все филиалы' }].concat(data.data);
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.error('cant fetch affiliates: ' + ex);
            }
        },
        editAction(row) {
            this.$router.push({ name: 'reviewsEdit', params: { id: row.id } });
        },
        deleteAction(row) {
            this.modalData = row;
            this.modalType = 'delete';
            this.modalOpen = true;
        },
        async handlePublishToggle(row) {
            this.loading = true;

            try {
                const val = !row.is_published;
                const isPublished = val ? 1 : 0;
                await this.axios.put(`/reviews/${row.id}/toggle-published`, { is_published: isPublished });
                await this.fetchData(this.getQuery());
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant toggle published: ' + ex);
            }
        },
        onDeleteModalAccepted(data) {
            this.axios
                .delete('/reviews/' + data.id)
                .then((res) => {
                    this.fetchData(this.$route.query);
                    this.modalOpen = false;
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
        handleAffiliateSelect(id) {
            this.$router.push({ query: { affiliate_id: id } });
        },
        handleAffiliateClear() {
            const query = JSON.parse(JSON.stringify(this.getQuery()));
            delete query.affiliate_id;

            this.$router.push({ query });
        },
    },
    async beforeRouteUpdate(to, from, next) {
        const query = this.getQuery(to.query);
        await this.fetchData({ ...query });
        next();
    },
};
</script>

<style></style>
